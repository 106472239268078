import * as React from 'react';
import classnames from 'classnames';

export interface AccordionItemProps {
  className?: string;
  children: React.ReactNode;
  question: string;
}

/**
 * @name AccordionItem
 * @description Content that expands inside an accordion.
 */
export const AccordionItem = (props: AccordionItemProps) => {
  const { className, children, question } = props;

  // Hooks
  const [isSelected, setIsSelected] = React.useState(false);

  // Handlers
  const handleClick = () => {
    setIsSelected(!isSelected);
  };

  return (
    <div
      className={classnames(
        'border-0 border-t-[3px] border-solid border-t-gray-2 px-8',
        className
      )}
    >
      <div
        className="flex cursor-pointer items-center justify-between"
        onClick={handleClick}
      >
        <h2 className="text-[22px]">{question}</h2>
        <h1 className="m-0 pl-8 text-5xl text-pine">
          {isSelected ? '-' : '+'}
        </h1>
      </div>
      <div className="leading-7">{isSelected ? children : null}</div>
    </div>
  );
};
