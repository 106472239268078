import * as React from 'react';
import classnames from 'classnames';

import { HaldiStickyNote } from '@src/routes/common/components/HaldiStickyNote';

import styles from './styles.module.css';

export interface HomepageRecommendationsProps {
  className?: string;
}

/**
 * @name HomepageRecommendations
 * @description tbd...
 */
export const HomepageRecommendations = (
  props: HomepageRecommendationsProps
) => {
  const { className } = props;

  const copy = `We look for clean ingredients but believe clean is not nearly enough. It also needs to work for your unique skin needs, preferences and values. So we believe in Clean-For-You.`;

  // Styles
  const tailwind = 'ui-container-xl flex flex-column';
  const cssComponent = classnames(styles.component, className);
  const cssContainer = classnames(tailwind, styles.container);

  return (
    <div className={cssComponent}>
      <div className={cssContainer}>
        <div className={styles.left}>
          <h2 className={styles.heading}>
            We only recommend products that are{' '}
            <span className="text-pine">Clean-For-You.</span>
          </h2>
          <p className={styles.copy}>{copy}</p>
        </div>

        <div className={styles.right}>
          <div className="relative">
            <img
              alt=""
              className={styles.image}
              src="/images/rebrand/recs.svg"
            />
            <HaldiStickyNote className={styles.sticky} rotate={4} size="large">
              <div className="text-20 md:p-1x md:text-36">
                Clean ingredients <br />+ efficacy
              </div>
            </HaldiStickyNote>
          </div>
          <p className={styles.copyMobile}>{copy}</p>
        </div>
      </div>
    </div>
  );
};
