import * as React from 'react';
import classnames from 'classnames';

import { HaldiButton } from '@src/routes/common/components/HaldiButton/HaldiButton';
import { FaqAccordion } from '../../FaqAccordion/FaqAccordion';
import { FaqHeading } from '../../FaqHeading/FaqHeading';

export interface HomepageFaqsContainerProps {
  className?: string;
}

/**
 * @name HomepageFaqsContainer
 * @description Container of the entire FAQ accordion.
 */
export const HomepageFaqsContainer = (props: HomepageFaqsContainerProps) => {
  const { className } = props;

  return (
    <div
      className={classnames(
        'm=[5rem] m-1 flex-col pb-10 pt-14 md:max-w-screen-md',
        className
      )}
    >
      <FaqHeading className="mb-2 text-center" />
      <FaqAccordion />
      <div className="flex justify-center">
        <HaldiButton
          bordersX="turmeric"
          className="mt-14"
          href="/faq"
          thick={true}
        >
          More FAQs
        </HaldiButton>
      </div>
    </div>
  );
};
