import * as React from 'react';
import classnames from 'classnames';

import { RoutineGroup } from '@src/generated/hooks';
import { Data, DataProduct } from './data';

import styles from './homepageStory.module.css';

export interface HomepageStoryProps {
  className?: string;
  story: Data;
}

/**
 * @name HomepageStory
 * @description A single "story" of our <HomepageStories />
 */
export const HomepageStory = (props: HomepageStoryProps) => {
  const { className, story } = props;

  // Hooks
  const [state, _setState] = React.useState<RoutineGroup>('morning');

  // Setup
  const { copy, heading, image, instagram, name, routines } = story;
  const routine = routines[state];

  // Styles
  const cssComponent = classnames(styles.component, className);
  const cssContainer = classnames('flex', styles.container);

  // Markup
  const renderProducts = (product: DataProduct, index: number) => {
    return (
      <div className={styles.product} key={product.image}>
        <div
          className={styles.square}
          style={{ backgroundImage: `url(${product.image})` }}
        >
          {/* <img src={} alt="" /> */}
        </div>
        <div className="mt-2x flex items-center">
          <h3 className={styles.productHeading}>0{index + 1}</h3>
          {product.copy && <p>{product.copy}</p>}
        </div>
      </div>
    );
  };

  return (
    <div className={cssComponent}>
      <h2
        className={styles.heading}
        dangerouslySetInnerHTML={{ __html: heading }}
      />
      <div className={cssContainer}>
        <div className={styles.image}>
          <img alt={name} src={image} />
          <div className={styles.instagram}>
            {name} - @{instagram}
          </div>
        </div>
        <div className="flex w-full flex-col">
          <div className="flex-1" />
          <h3
            className={styles.subhead}
            dangerouslySetInnerHTML={{ __html: copy }}
          />
          <div className="flex-1" />
          <div className={styles.products}>
            {routine.products.map(renderProducts)}
          </div>
        </div>
      </div>
    </div>
  );
};
