import * as React from 'react';
import classnames from 'classnames';

export interface FaqHeadingProps {
  className?: string;
}

/**
 * @name FaqHeading
 * @description Faq
 */
export const FaqHeading = (props: FaqHeadingProps) => {
  const { className } = props;

  return (
    <div className={classnames('', className)}>
      <h1 className="md:text-[42px]">Frequently Asked Questions</h1>
    </div>
  );
};
