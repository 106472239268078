import * as React from 'react';
import classnames from 'classnames';

import { Data, data } from './data';
import { HomepageStory } from './HomepageStory';

import styles from './homepageStories.module.css';

export interface HomepageStoriesProps {
  className?: string;
}

/**
 * @name HomepageStories
 * @description We've abstracted this bit from each of the individual stories
 * so we can easily add the Carousel logic in here.
 */
export const HomepageStories = (props: HomepageStoriesProps) => {
  const { className } = props;

  // Styles
  const cssComponent = classnames(styles.component, className);

  // Markup
  const renderStory = (story: Data) => {
    return <HomepageStory key={story.instagram} story={story} />;
  };

  return (
    <div className={cssComponent}>
      <div className="ui-container-xl">{data.map(renderStory)}</div>
    </div>
  );
};
