import * as React from 'react';
import classnames from 'classnames';

import { AppLink } from '@src/services/app/components/AppLink/AppLink';
import { Data, data } from './data';

import styles from './index.module.css';

export interface HomepageBrandsProps {
  className?: string;
}

/**
 * @name HomepageBrands
 * @description Responsive layout of some brands we carry and just so
 * happen to really like using.
 */
export const HomepageBrands = (props: HomepageBrandsProps) => {
  const { className } = props;

  // Styles
  const cssComponent = classnames(styles.component, className);

  // Markup
  const renderData = (entry: Data, index: number) => {
    const { image, title, width } = entry;
    // TODO: Fix this, brands link should go to the exact section
    // const nextSlug = image.match(/[0-9a-z-]+(?=.png)/i);
    // const url = '/brands/' + nextSlug + '#details';
    const url = '/brands';

    return (
      <AppLink key={index} href={url} className="mx-1x shrink-0">
        <img
          alt={title}
          className={styles.brand}
          key={index}
          src={image}
          width={width}
        />
      </AppLink>
    );
  };

  return (
    <>
      <div className={cssComponent}>
        <div className="ui-container-xl">
          <h2 className={styles.heading}>A few of our go to brands</h2>
        </div>
      </div>
      <div className={styles.content}>{data.map(renderData)}</div>
    </>
  );
};
