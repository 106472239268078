import * as React from 'react';
import classnames from 'classnames';

import { AppLink } from '@src/services/app/components/AppLink/AppLink';
import { AccordionItem } from '../../../common/components/AccordionItem/AccordionItem';

export interface FaqAccordionProps {
  className?: string;
}

/**
 * @name FaqAccordion
 * @description Drop down FAQ menu bar with expandable content.
 */
export const FaqAccordion = (props: FaqAccordionProps) => {
  const { className } = props;

  return (
    <div className={classnames('', className)}>
      <AccordionItem question="How do I get my custom skincare routine?">
        <ol className="m-0 list-inside pl-0 text-16 leading-6 md:text-20 md:leading-7">
          <li>
            <strong>Fill out our skin quiz:</strong> Tell us about your skin
            type, skin concerns, skin goals, budget, and the skincare products
            you currently use. It only takes a few minutes to fill out the quiz.
          </li>
          <br />
          <li>
            <strong>Get your custom skincare routine:</strong> Within 1-2
            business days, we will email you an assessment of your current
            skincare routine along with a brand new daily routine that
            incorporates both your original skincare products and your Haldi
            recommended products. We'll tell you which products to keep, to
            swap, and what's missing from your current routine.
          </li>
          <br />
          <li>
            <strong>Shop your Haldi picks:</strong> We'll create your very own
            customized Haldi store, stocked with skincare products picked
            especially for you based on your skin quiz results. You can purchase
            whatever you need and we'll mail it directly to you. We never markup
            retail, and returns are always free.
          </li>
          <br />
        </ol>
      </AccordionItem>
      <AccordionItem question="Are Haldi products clean?">
        <p className="m-0 md:text-20">
          Yes, Haldi recommendations are clean but we believe clean is not
          enough. Just because a product is clean does not mean it will work for
          you. Something might be too strong for your sensitive skin, or too
          aggravating for your acneic skin. So we believe in Clean-For-You.
        </p>
        <br />
        <p className="md:text-20">
          All of the skincare products that we carry do not contain harmful
          ingredients like Sodium Lauryl Sulfate, Mineral Oil, Parabens,
          Formaldehyde, among many others. You can read more about our clean
          standard{' '}
          <AppLink className="text-inherit text-gray-6" href="/faq">
            here
          </AppLink>
          .
        </p>
        <br />
      </AccordionItem>
      <AccordionItem question="What's included in my custom skincare routine?">
        <p className="m-0 md:text-20">
          Everyone who fills out the skin quiz receives a skincare routine that
          is personalized and unique.
        </p>
        <br />
        <p className="m-0 md:text-20">
          A Haldi routine will typically include a cleanser, a moisturizer, a
          sunscreen, and possibly a serum, exfoliator, or mask depending on your
          skin goals and skin concerns. We normally add between 4-6 products to
          your personal store after you initially sign up. Once you get your
          personal store, you can buy what you need.
        </p>
        <br />
        <p className="md:text-20">
          We even carry some body products so if you're interested in checking
          them out just leave a comment in your profile and we'll add them to
          your store.
        </p>
        <br />
      </AccordionItem>
      <AccordionItem question="How do I find out my skin type?">
        <p className="md:text-20">
          Everyone's skin is different. There are more than four skin types but
          we make it easy to find out what your skin type is. When you take our
          skin quiz, you will answer a few questions about how your skin feels
          throughout the day, your location, and any skin sensitivities. This
          will help us determine whether you have combination skin, dry skin, or
          another skin type.
        </p>
        <br />
      </AccordionItem>
      <AccordionItem question="Who's customizing my skincare routine?">
        <p className="md:text-20">
          We have a passionate team that includes aestheticians and skin
          coaches. The team has been trained on every single product and brand
          that Haldi carries so that they can make the most personalized
          recommendations for your skin.
        </p>
        <br />
      </AccordionItem>
      <AccordionItem
        className="border-b-[3px] border-solid border-b-gray-2"
        question="Can I use other skincare products with my Haldi routine?"
      >
        <p className="md:text-20">
          Yes! In fact we are big fans of you continuing to use products you
          love and find are working for you. If you have some products from
          elsewhere, we help incorporate them into your custom routine so that
          you know exactly how to layer your products and avoid ingredient
          conflicts. We also check your products for any Ingredient Offenders
          that might not be the best fit for your skin (for example, pore
          clogging mineral oils), and will let you know if you should consider
          switching.
        </p>
        <br />
      </AccordionItem>
    </div>
  );
};
