import * as React from 'react';
import classnames from 'classnames';

import { HomepageFaqsContainer } from './HomepageFaqsContainer/HomepageFaqsContainer';

export interface HomepageFaqsProps {
  className?: string;
}

/**
 * @name HomepageFaqs
 * @description FAQs feature component.
 */
export const HomepageFaqs = (props: HomepageFaqsProps) => {
  const { className } = props;

  return (
    <div
      className={classnames(
        'flex justify-center border-0 border-y-[6px] border-solid border-b-forest border-t-pine p-4',
        className
      )}
    >
      <HomepageFaqsContainer />
    </div>
  );
};
