import * as React from 'react';
import { color } from '@haldi/style-guide';
import classnames from 'classnames';

import { HaldiLogo } from '@src/routes/common/components/HaldiLogo';
import { HaldiStickyNote } from '@src/routes/common/components/HaldiStickyNote';
import { Data, data } from './data';

// import { useAnimation } from './useAnimation';
import styles from './styles.module.css';

export interface HomepageExperienceProps {
  className?: string;
}

/**
 * @name HomepageExperience
 * @description tbd...
 */
export const HomepageExperience = (props: HomepageExperienceProps) => {
  const { className } = props;

  // Hooks
  const refEntryPoint = React.useRef<HTMLDivElement>(null);

  // Styles
  const cssComponent = classnames(styles.component, className);
  const cssExperiences = classnames('flex', styles.experiences);
  const cssExpHeading = classnames('text-turmeric', styles.expHeading);
  const cssLogo = classnames('mx-1x md:mx-3x', styles.logo);
  const cssSubhead = classnames('text-turmeric', styles.subhead);

  // Setup
  const { white, turmeric, stormy } = color;
  const colors = [stormy, white, turmeric, stormy, turmeric, turmeric, white, stormy, turmeric, white] // prettier-ignore

  // Markup
  const renderExperience = (experience: Data, index: number) => {
    const { copy, title } = experience;
    const srcDesktop = `/images/rebrand/dots-${index + 1}-desktop.svg`;
    const srcMobile = `/images/rebrand/dots-${index + 1}.svg`;

    const cssImgDesktop = classnames('ui-desktop', styles.image);
    const cssImgMobile = classnames('ui-mobile', styles.image);

    return (
      <div className={styles.experience} key={title}>
        <img alt="" className={cssImgDesktop} src={srcDesktop} />
        <img alt="" className={cssImgMobile} src={srcMobile} />
        <h3
          className={cssExpHeading}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p>{copy}</p>
      </div>
    );
  };

  // Animation
  // useAnimation(refEntryPoint, `.${styles.experience}`);

  return (
    <div className={cssComponent}>
      <div className="ui-container-xl">
        <h2 className={styles.heading}>
          the <HaldiLogo className={cssLogo} colors={colors} /> experience
        </h2>

        <div className={cssExperiences} ref={refEntryPoint}>
          {data.map(renderExperience)}
        </div>

        <HaldiStickyNote className={styles.sticky} size="large" rotate={5}>
          <div className="p-2x text-[40px]">
            keep using
            <br />
            what you
            <br />
            &nbsp;love!
          </div>
        </HaldiStickyNote>

        <img
          alt=""
          className={styles.hearts}
          src="/images/rebrand/heart-desktop.svg"
        />

        <h3 className={cssSubhead}>
          If you love your cleanser, keep it!
          <br />
          <span className="text-white">
            we’re just here to fill in the gaps
          </span>
        </h3>
      </div>
    </div>
  );
};
