export interface Data {
  copy: string;
  title: string;
}

export const data: Data[] = [
  {
    copy: 'We’ll start with your bathroom shelf, looking for Ingredient Offenders, the “trans -fats of skincare”, ingredients no one needs to be using (like mineral oil).',
    title: 'Toss the <span>offender</span>'
  },
  {
    copy: 'We never want you to toss everything and start from scratch. We build a personalized skincare routine around what you’re already using and loving.',
    title: 'Keep using <span>your faves</span>'
  },
  {
    copy: 'Your skin coach recommends products for the gaps in your skincare routine. Free shipping on orders over $50, no subscriptions.',
    title: 'Shop your <span>Haldi picks</span>'
  },
  {
    copy: 'We’ll build your personal day and night skincare routine, including products you’re already using. All you have to do is follow.',
    title: 'Your step-by-step <span>routine</span>'
  }
];
