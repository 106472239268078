import * as React from 'react';
import classnames from 'classnames';

import { SITE_SURVEY_CTA, SITE_SURVEY_PATH } from '@src/config/constants';
import { HaldiButton } from '@src/routes/common/components/HaldiButton';

import styles from './styles.module.css';

export interface HomepageGetStartedProps {
  className?: string;
}

/**
 * @name HomepageGetStarted
 * @description Lets try and get this user over to the Survey app!
 */
export const HomepageGetStarted = (props: HomepageGetStartedProps) => {
  const { className } = props;

  // Styles
  const cssComponent = classnames('text-center', styles.component, className);

  return (
    <div className={cssComponent}>
      <div className="ui-container-xl">
        <h2 className={styles.heading}>
          Let’s get <span>started</span>
        </h2>
        <p className={styles.subhead}>
          What brings you <span>to Haldi today?</span>
        </p>
        <div className={styles.bordered}>
          Tell us about yourself{' '}
          <span className="text-turmeric">(and your skin).</span>
        </div>
        <HaldiButton
          bordersX="turmeric"
          bordersY="pine"
          className="mx-auto text-white"
          href={SITE_SURVEY_PATH}
          thick={true}
        >
          {SITE_SURVEY_CTA}
        </HaldiButton>
      </div>
    </div>
  );
};
