export interface DataProduct {
  image: string;
  copy?: string;
}

export interface DataRoutine {
  products: DataProduct[];
  title: string;
}

export interface Data {
  copy: string;
  heading: string;
  instagram?: string;
  image: string;
  name: string;
  routines: {
    morning: DataRoutine;
    evening: DataRoutine;
    week: DataRoutine;
  };
}

export const data: Data[] = [
  {
    copy: `Her Haldi routine is quick but nourishes her sensitive skin. Perfect for a new mom and busy exec!`,
    heading: `Sima assumed self care <span class="text-pine">meant long hours.</span>`,
    image: `/images/webp/home-story-1.webp`,
    instagram: 'simagoo',
    name: 'Sima Gandhi',
    routines: {
      evening: {
        products: [],
        title: 'Night routine'
      },
      morning: {
        products: [
          {
            image: `/images/stories/sima-cleanser.png`
          },
          {
            image: `/images/stories/sima-serum.png`
          },
          {
            image: `/images/stories/sima-oil.png`
          },
          {
            image: `/images/stories/sima-existing.png`
          }
        ],
        title: 'Day routine'
      },
      week: {
        products: [],
        title: 'Night routine'
      }
    }
  }
];
